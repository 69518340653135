<template>
  <div class="container">
    <van-cell-group inset style="margin-top: 60px">
      <van-cell>
        <template #default>
          <h3>商品信息</h3>
        </template>
      </van-cell>
      <van-card :desc="medicalRecord.number + '副'">
        <template #title>
          <div class="order-card__title">
            <span>新冠预防中药</span>
            <span><span style="color: #b1080c">￥8.00</span> / 副</span>
          </div>
        </template>
        <template #num>
          <span>￥{{ medicalRecord.amount.toFixed(2) }}</span>
        </template>
        <template #thumb>
          <img src="../../assets/img/image2.png" alt="" />
        </template>
        <template #tags>
          <van-tag v-if="medicalRecord.changeStatus >= 7">交易关闭</van-tag>
          <van-tag plain type="danger" v-else>{{
            changeStatus[medicalRecord.changeStatus]
          }}</van-tag>
        </template>
      </van-card>
      <van-cell title="取货人" :value="medicalRecord.name" :border="false" />
      <van-cell title="单价" value="￥8.00 / 副" :border="false" />
      <van-cell
        title="数量"
        :value="medicalRecord.number + '副'"
        :border="false"
      />
      <van-cell
        title="订单备注"
        :label="medicalRecord.remark ? medicalRecord.remark : '无备注'"
        :border="false"
      />
    </van-cell-group>
    <van-cell-group inset style="margin-top: 20px; margin-bottom: 20px">
      <van-cell>
        <template #default>
          <h3>订单信息</h3>
        </template>
      </van-cell>
      <van-cell title="订单编号" :value="medicalRecord.id" :border="false" />
      <van-cell
        title="订单状态"
        :value="changeStatus[medicalRecord.changeStatus]"
        :border="false"
      />
      <van-cell title="支付方式" value="微信支付" :border="false" />
      <van-cell
        title="支付金额"
        :value="'￥' + medicalRecord.amount.toFixed(2)"
        :border="false"
      />
      <van-cell
        title="创建时间"
        :value="medicalRecord.createTime"
        :border="false"
      />
      <van-cell>
        <div class="order-card-timeTips" v-if="medicalRecord.changeStatus == 0">
          请在<span>{{
            moment(medicalRecord.createTime)
              .add(1, "days")
              .format("YYYY-MM-DD HH:mm:ss")
          }}</span
          >前完成支付
        </div>
        <div class="cell-button">
          <!-- <van-button
            size="small"
            v-if="medicalRecord.changeStatus == 0"
            @click.stop="handleCancleOrder(medicalRecord)"
            >取消订单</van-button
          > -->
          <van-button
            size="small"
            type="info"
            v-if="medicalRecord.changeStatus == 0"
            @click.stop="handlePay(medicalRecord)"
            >继续付款</van-button
          >
          <van-button
            size="small"
            type="danger"
            v-if="medicalRecord.changeStatus == 3"
            @click.stop="handleRefund(medicalRecord)"
            >退款</van-button
          >
        </div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "traditionalMedicalRecordDetail",
  data() {
    return {
      medicalRecord: {
        amount: 0,
        number: 1,
      },
      moment: require("moment"),
      changeStatus: {
        0: "待支付",
        1: "支付中",
        2: "异常",
        3: "待核对",
        4: "已自提",
        5: "待审核",
        6: "退款中",
        7: "用户取消",
        8: "医院取消",
        9: "自动取消",
        10: "已退款",
        11: "拒绝退款",
      },
    };
  },
  methods: {
    getDataList() {
      let param = {
        id: this.$route.query.id,
      };
      this.$http.get("/api/medicine/info", param).then((result) => {
        this.medicalRecord = result.data;
      });
    },
    /**
     * 取消订单
     */
    handleCancleOrder(item) {
      this.$dialog
        .confirm({
          message: "确定取消订单吗?",
          confirmButtonColor: "#00D48A",
        })
        .then(() => {
          this.$http.put("/api/medicine", { id: item.id }).then((result) => {
            this.$toast("已取消订单");
            this.getDataList();
          });
        });
    },
    /**
     * 退款
     */
    handleRefund(item) {
      this.$dialog
        .confirm({
          message: "确定退款吗?",
          confirmButtonColor: "#00D48A",
        })
        .then(() => {
          this.$http
            .get("/api/medicine/refund", { id: item.id })
            .then((result) => {
              this.$toast("已申请退款");
              this.getDataList();
            });
        });
    },
    /**
     * 继续付款
     */
    handlePay(item) {
      let postData = {
        id: item.id,
      };
      this.$http.get("/api/medicine/jsapi", postData).then((result) => {
        this.appId = result.data.appId;
        this.timestamp = result.data.timeStamp;
        this.nonce = result.data.nonceStr;
        this.packageName = result.data.package;
        this.signType = result.data.signType;
        this.signature = result.data.paySign;
        this.isWeChat();
      });
    },
    isWeChat() {
      let self = this;
      if (typeof WeixinJSBridge == "undefined") {
        self.onBridgeReady();
        Toast({ message: "请在微信客户端进行支付操作" });
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            self.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", self.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", self.onBridgeReady());
        }
      } else {
        self.onBridgeReady();
      }
    },
    onBridgeReady() {
      let self = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: self.appId, //公众号名称，由商户传入
          timeStamp: self.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: self.nonce, //随机串
          package: self.packageName,
          signType: self.signType, //微信签名方式：
          paySign: self.signature, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            self.getDataList();
          }
        }
      );
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style scoped>
.container {
  text-align: left;
}
.van-cell__title {
  flex: none;
  width: 30%;
}
.van-card {
  background-color: #fff;
  font-size: 16px;
}
.cell-button {
  display: flex;
  justify-content: flex-end;
}
.cell-button .van-button {
  margin: 0 5px;
}
.order-card__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-card-timeTips {
  font-size: 0.35rem;
  margin: 0.2rem 0;
  text-align: right;
}
.order-card-timeTips span {
  color: #3f9efe;
}
</style>
